import { useState, forwardRef } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import InvitationForm from './invitationForm'
import InvitationUpload from './invitationUpload'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <Box
      role="tabpanel"
      component="div"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      maxHeight={660}
      sx={{
        overflow: 'scroll',
        ...StylesScrollBar
      }}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function InvitationTabs({
  isOpen,
  onClose
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue)

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      TransitionComponent={Transition}
      aria-describedby="dialog-slide-description"
    >
      <DialogContent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="recharge-tabs"
          variant="scrollable"
          TabIndicatorProps={{
            sx: {
              background: 'transparent'
            }
          }}
          sx={{ marginBottom: 2 }}
        >
          <Tab
            {...a11yProps(0)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 0 ? COLORS.black : COLORS.gray1}
              >
                Add employee
              </Typography>
            }
            sx={{
              width: { xs: '50%', sm: '340px' },
              justifyContent: 'space-between',
              background: value === 0 ? '#FFDA8D' : ''
            }}
          />
          <Tab
            {...a11yProps(1)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 1 ? COLORS.black : COLORS.gray1}
              >
                Upload CSV
              </Typography>
            }
            sx={{
              width: { xs: '50%', sm: '340px' },
              justifyContent: 'space-between',
              background: value === 1 ? '#FFDA8D' : ''
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <InvitationForm onClose={onClose} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InvitationUpload onClose={onClose} />
        </TabPanel>
      </DialogContent>
    </Dialog>
  )
}
